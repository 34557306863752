import {
    getClosest,
    isDefined,
    settings,
    getQueryVariable,
    checkForValidCSRFToken,
    isMobile,
    iePolyfills,
} from '@app/core'
iePolyfills()
import { h, app } from 'hyperapp'
import { t } from 'i18next'
import { location } from '@hyperapp/router/src/index'
import {
    withTranslation,
    withStorage,
    withSecurity,
    withKafkaEvents,
} from '@app/utils'
import { Route, Switch, ProgressBar, Meta, UnsupportedBrowserBanner } from '@app/elements'
import { displayErrors } from '@app/api/errors'
// import persist from 'hyperapp-persist';
//
import * as constants from '@app/constants'
const { APP_CUSTOMISATION } = settings

import * as serviceWorker from './registerServiceWorker'
import icoClose from '@app/img/ico/ico-close.png'
import icoClosex2 from '@app/img/ico/ico-close@2x.png'

import { BotFooterView } from '@app/layouts/botfooter'

import {
    Login,
    Register,
    Patients,
    Patient,
    Alerts,
    Alertsic,
    Alertscurve,
    Alertsrds,
    Account,
    Mentions,
    MentionsPublic,
    Help,
    Consent,
    UnregisterSW,
    QuatreCentQuatre,
    RedirectingWay,
} from '@app/modules'
import { createLocaleFromLangAndUserObject } from './core/localeHandlers/createLocaleFromLangAndUserObject'

const state = {
    location: location.state,
    rendering: false,
    loaded: 'nope', // this set overflow hidden on body until complete load
    customerssoname: APP_CUSTOMISATION.toLowerCase(), // reference ssoname for custo
    meta: null, // meta are raw setted in public/index.html, then we override them if needed
    lang: 'fr-FR', // reference language
}

const actions = {
    onComponentCreate: (props) => (state, actions) => {
        actions.removeAPITokenIfNeeded()
        actions.defineListeners()
    },
    onUpdate: () => (state, actions) => {
        let error = getQueryVariable('e')
        if (!isDefined(error) || error === false) {
            error = getQueryVariable('error')
        }
        if (isDefined(error) && error !== false) {
            error = unescape(error).replace(/\+/g, ' ')
            displayErrors(error, 10000)
        }
        checkForValidCSRFToken()
        //
        const existingLocale = localStorage.getItem('language')
        if (existingLocale) {
            localStorage.setItem('locale', existingLocale.replace('_', '-'))
            localStorage.removeItem('language')
        }
    },
    location: location.actions,
    pendingAjaxQuery: () => (state, actions) => {
        let scheme = getQueryVariable('scheme', false) // redirect to mobile app
        if (
            typeof scheme !== 'undefined' &&
            scheme !== '' &&
            scheme !== null &&
            scheme !== false
        ) {
            window.location.href = scheme
            return false
        }

        let customerssoname = null
        let forcedLanguage = null
        if (settings.APP_DEBUG === 'true' || settings.APP_DEBUG === true) {
            // get customer by query variable only if dev
            customerssoname = getQueryVariable('customerssoname', false)
            let customersso = getQueryVariable('customersso', false)
            customerssoname =
                customerssoname === false ? customersso : customerssoname // look at query parameter
            customerssoname =
                customerssoname === false
                    ? state.customerssoname
                    : customerssoname // if nothing, take CUSTO_SSO constant
        } else {
            // else we use the .env customer, no dyn, each customer has his instance
            customerssoname = state.customerssoname
        }
        actions.setCustomer(customerssoname)

        actions.loadCSS()
        actions.overrideMeta(customerssoname)
        actions.defineLanguage(forcedLanguage)
        localStorage.setItem('isMobile', isMobile())

        var intervalID = window.setInterval(function () {
            if (document.readyState === 'complete') {
                // actions.setLoaded('loaded');
                window.clearInterval(intervalID)
                // actions.rendering();
            }
        }, 500)
    },
    loadCSS: () => (state, actions) => {
        require('@app/custo/_index.scss')
        // try {
        //     // require('@app/custo/' + customerssoname + '/index.scss');
        //     require('@app/custo/theraflow/index.scss');
        // } catch (error) {
        //     require('@app/custo/theraflow/index.scss');
        //     actions.setCustomer('theraflow');
        // }
    },
    overrideMeta: (customerssoname) => (state, actions) => {
        let ccustomer = constants.custo.CUSTOMERS[customerssoname]
        if (isDefined(ccustomer)) {
            let meta = {
                customer: customerssoname,
                title: ccustomer.title,
                description: t(ccustomer.description),
                favicon:
                    window.location.origin +
                    '/favicon/' +
                    (customerssoname !== false
                        ? customerssoname
                        : 'theraflow') +
                    '/favicon.ico',
            }
            actions.setMeta(meta)
        }
    },
    defineLanguage: (forcedLanguage) => (state, actions) => {
        let browserlang =
            window.navigator.userLanguage || window.navigator.language
        let systemLang = state.lang
        if (forcedLanguage !== null) {
            browserlang = forcedLanguage
            systemLang = forcedLanguage
        }
        //
        let selectedLang = null
        let sessionUser = localStorage.getItem('adminuser')
        if (isDefined(sessionUser)) {
            sessionUser = JSON.parse(sessionUser)
            if (isDefined(sessionUser)) {
                selectedLang = sessionUser.locale
            }
        }
        if (!isDefined(selectedLang)) {
            if (browserlang.indexOf('fr') === -1) {
                selectedLang = browserlang
            } else if (systemLang.indexOf('fr') === -1) {
                selectedLang = systemLang
            }
        }
        //
        if (!isDefined(selectedLang) && isDefined(forcedLanguage)) {
            selectedLang = forcedLanguage
        }
        //
        let languageFromURL = sessionStorage.getItem('urlLanguage')
        if (isDefined(languageFromURL)) {
            selectedLang = languageFromURL
            sessionStorage.removeItem('urlLanguage')
        }
        //
        if (isDefined(selectedLang)) {
            selectedLang = selectedLang.replace('_', '-')
            localStorage.setItem(
                'locale',
                createLocaleFromLangAndUserObject(selectedLang)
            )
            actions.setLang(selectedLang)
            window.main.changeLanguage(selectedLang)
        }
    },
    removeAPITokenIfNeeded: () => (state, actions) => {
        let expiration = localStorage.getItem('expiration')
        let removeToken = false
        if (!isDefined(expiration) || expiration == false) {
            removeToken = true
        } else {
            let expirationDate = expiration.split(' ')[0].split('-')
            let expirationTime = expiration
                .split(' ')[1]
                .split('.')[0]
                .split(':')
            let dateExpiration = new Date(
                Date.UTC(
                    expirationDate[0],
                    +expirationDate[1] - 1,
                    expirationDate[2],
                    expirationTime[0],
                    expirationTime[1],
                    expirationTime[2]
                )
            )
            let dateOffset = new Date().getTimezoneOffset()
            dateExpiration.setMinutes(dateExpiration.getMinutes() - dateOffset)

            let now = new Date()
            now.setMinutes(now.getMinutes() - dateOffset)

            if (dateExpiration.getTime() < now.getTime()) {
                removeToken = true
            }
        }
        if (removeToken) {
            localStorage.removeItem('expiration')
            localStorage.removeItem('apitoken')
        }
    },
    defineListeners: () => (state, actions) => {
        //
    },
    setCustomer: (newState) => (state) => ({
        customerssoname: newState,
    }),
    setMeta: (newState) => (state) => ({
        meta: newState,
    }),
    setLang: (newState) => (state) => ({
        lang: newState,
    }),
    setLoaded: (newState) => (state) => ({
        loaded: newState,
    }),
    rendering: () => (state) => ({
        rendering: !state.rendering,
    }),
}

const view = (state, actions) => (
    <div
        id="btzRoot"
        class={state.customerssoname}
        onupdate={actions.onUpdate}
        oncreate={actions.pendingAjaxQuery}
        data-loading={'loaded'}
        key={state.customerssoname}
    >
        {state.meta !== null && (
            <div key={state.meta.customer}>
                <Meta {...state.meta}></Meta>
            </div>
        )}
        <ProgressBar customer={state.customerssoname}></ProgressBar>
        <UnsupportedBrowserBanner customer={state.customerssoname}/>
        <Switch>
            {constants.custo.CUSTOMERS[
                state.customerssoname
            ].modules.noSWRoutes.map((route) => (
                <Route path={route}>{() => <UnregisterSW />}</Route>
            ))}
            <Route path="/mentions">
                {() => <Mentions customer={state.customerssoname} />}
            </Route>
            <Route path="/public/mentions">
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <MentionsPublic
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/help">
                {() => <Help customer={state.customerssoname} />}
            </Route>
            <Route path="/consent">
                {() => <Consent customer={state.customerssoname} />}
            </Route>
            <Route path="/chv">
                {() => (
                    <RedirectingWay
                        customer={state.customerssoname}
                        targetapi={'chv'}
                    />
                )}
            </Route>
            <Route path="/ijb">
                {() => (
                    <RedirectingWay
                        customer={state.customerssoname}
                        targetapi={'ijb'}
                    />
                )}
            </Route>

            <Route path="/patients/listing">
                {({ location, match }) => (
                    <Patients
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/patient/edit/:id">
                {({ location, match }) => (
                    <Patient
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/patient/add">
                {({ location, match }) => (
                    <Patient
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/alerts/listing">
                {({ location, match }) => (
                    <Alerts
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/alertsic/listing">
                {({ location, match }) => (
                    <Alertsic
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/alertscurve/listing">
                {({ location, match }) => (
                    <Alertscurve
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>
            <Route path="/alertsrds/listing">
                {({ location, match }) => (
                    <Alertsrds
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/account/edit">
                {({ location, match }) => (
                    <Account
                        match={match}
                        location={location}
                        customer={state.customerssoname}
                    />
                )}
            </Route>

            <Route path="/register" parent>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Register
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/:lang/register" parent>
                {/* TEMP - TO REMOVE */}
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Register
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                            defineLanguage={actions.defineLanguage}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/login/recovery" exact={true}>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/login" exact={true}>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>
            <Route path="/" exact={true}>
                {({ location, match }) => (
                    <dummy class="dummy-root">
                        <Login
                            match={match}
                            location={location}
                            customer={state.customerssoname}
                        />
                        <BotFooterView
                            customer={state.customerssoname}
                        ></BotFooterView>
                    </dummy>
                )}
            </Route>

            <Route>
                {() => <QuatreCentQuatre customer={state.customerssoname} />}
            </Route>
        </Switch>

        <span id="btzRuler"></span>
        <div id="btzMainOverlay"></div>
        <div id="btzMainMessage" data-kind="error" style="display: none;">
            <div
                id="btzMainMessageCross"
                onclick={(e) => {
                    getClosest(e.target, '#btzMainMessage').classList.remove(
                        'active'
                    )
                }}
            >
                <img src={icoClose} srcset={`${icoClosex2} 2x`} alt="" />
            </div>
            <div id="btzMaintextContent"></div>
        </div>
    </div>
)

/*app = persist(app, {
    storage: 'my-app/v1',
    include: [ 'router', 'player' ]
});*/

let appOnSteroids = app
appOnSteroids = withTranslation({})(appOnSteroids) // grabs all locales, set one as default
appOnSteroids = withKafkaEvents({})(appOnSteroids) // grabs all locales, set one as default
// appOnSteroids = withStorage({})(appOnSteroids) // syncs state objects in localstorage
appOnSteroids = withSecurity({})(appOnSteroids) // keeps an eye on tokens and triggers actions

if (settings.APP_DEBUG) {
    // const { withLogger } = require('@hyperapp/logger')
    // appOnSteroids = withLogger({})(appOnSteroids)
}

// const instance = persist(appOnSteroids(state, actions, view, document.body), {});
const instance = appOnSteroids(state, actions, view, document.body)
// eslint-disable-next-line
const unsubscribe = location.subscribe(instance.location)

window.main = instance

// instance.initStorage()

serviceWorker.unregister()
